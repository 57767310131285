import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/components/Main.css';

// Import images
import dataScienceImage from '../images/icons/services/data_science.png';
import businessAnalyticsImage from '../images/icons/services/business_analytics.svg';
import sapImage from '../images/icons/services/sap.svg';
import mobileLabsImage from '../images/icons/services/mobile_labs.svg';

const About = () => {
	
	useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('fixedHeader');
            if (window.pageYOffset > 0) {
                navbar.style.backgroundColor = '#21212190';
                navbar.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                navbar.style.top = '0';
            } else {
                navbar.style.backgroundColor = 'transparent';
                navbar.style.boxShadow = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <main>
            <Container>
                <div className="first">
                    <div className="first_box">
                        <h1>Welcome to <span>ASSKMIND</span></h1>
                        <p>
                            If you are wondering what it means by ASSKMIND... the truth is the founders love Quentin Tarantino movies,
                            especially Kill Bill where the sword of Uma Thurman is infamous. We loved the sword so much that our labs,
                            we believed must be exceptional, like the ASSKMIND steel!
                            <br /><br />
                            ASSKMIND has been in business since 2005. ASSKMIND's team is of highly talented engineers with decades of experience
                            dealing with Data related technologies, SAP and J2EE. Due to our core focus and strength of delivery, we have
                            sworn a 'brain oath' to Oracle and SAP
                            <br /><br />
                            <b>That said, ASSKMIND (HL) will work to meet your business needs, solve your technical dilemmas and provide the
                                information you need to stay ahead of the competition.</b>
                            <br /><br /><br />
                            To help you leverage our capabilities to your advantage, we offer enterprise solutions, co-managed solutions,
                            staff augmentation and business process outsourcing in areas such as:
                        </p>
                    </div>
                    <div className="second_box">
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={dataScienceImage} alt="Data Science" />
                                    <h2>Data Science</h2>
                                    <p>We at ASSKMIND can show how unstructured data can be transformed into a valuable input into decision making.
                                        We present an enormous opportunity to make organizations more agile, more efficient and more competitive.</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={businessAnalyticsImage} alt="Business Analytics" />
                                    <h2>Business Analytics</h2>
                                    <p>Anticipate opportunity, take action and make an impact. Tableau helps people see and understand their data.</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={sapImage} alt="SAP" />
                                    <h2>SAP</h2>
                                    <p>Learn why IDC MarketScape has named SAP Jam a leader in the IDC MarketScape: Worldwide Enterprise Social Networks vendor assessment.</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={mobileLabsImage} alt="Mobile Labs" />
                                    <h2>Mobile Labs</h2>
                                    <p>Mobility has upped the stakes and made the job much more challenging for software testers. In a piece that surveys mobile
                                        tools and strategies, Christina Mulligan of SDTimes interviewed Mobile</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="first_box">
                        <p>
                            <span style={{ fontSize: '20px' }}>We specialize in lowering cost of delivery, maintenance and help in cost reduction of that ever
                                shrinking IT budget.</span>
                            <br /><br />
                            Any service needed in the above areas be it projects help or consulting, we can help your organization, send us an email
                            <a href='mailto:hr@asskmind.com' style={{ textDecoration: 'none', cursor: 'pointer', color: '#df7d4d' }}>hr@asskmind.com</a> and our tech representative will contact you.
                        </p>
                    </div>
                </div>
            </Container>
            <div className="banner">
                <Container>
                    <h3>We've got your hiring, so you can stay focused on your business</h3>
                    <button onClick={() => window.location.href = '/contact'}>Enquire Now</button>
                </Container>
            </div>
        </main>
    );
};

export default About;
