import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import dataScienceIcon from '../images/icons/services/db_icon.png';
import businessAnalyticsIcon from '../images/icons/services/business_analytics.svg';
import sapIcon from '../images/icons/services/sap.svg';
import mobileLabsIcon from '../images/icons/services/mobile_labs.svg';
import slider1 from '../images/slider/1.jpg';
import slider2 from '../images/slider/2.jpg';
import slider3 from '../images/slider/3.jpg';
import slider4 from '../images/slider/4.jpg';
import slider5 from '../images/slider/5.jpg';
import slider6 from '../images/slider/6.jpg';
import '../styles/components/Main.css';

const Main = () => {
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('fixedHeader');
            if (window.pageYOffset > 0) {
                navbar.style.backgroundColor = '#21212190';
                navbar.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                navbar.style.top = '0';
            } else {
                navbar.style.backgroundColor = 'transparent';
                navbar.style.boxShadow = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const images = [
            slider1,
            slider2,
            slider3,
            slider4,
            slider5,
            slider6
        ];

        let currentImageIndex = 0;
        const changeHeaderImage = () => {
            currentImageIndex = (currentImageIndex + 1) % images.length;
            document.getElementById('header').style.background = `linear-gradient(to right, rgba(0, 0, 0, 86%), rgba(104, 104, 104, 25%)), url(${images[currentImageIndex]}) center no-repeat`;
        };

        document.getElementById('header').style.backgroundImage = `linear-gradient(to right, rgba(0, 0, 0, 86%), rgba(104, 104, 104, 25%)), url(${images[0]}) center no-repeat`;

        const interval = setInterval(changeHeaderImage, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <main>
            <Container>
                <div className="first">
                    <div className="first_box">
                        <h1>Welcome to <span>ASSKMIND</span></h1>
                        <p>
                            If you are wondering what it means by ASSKMIND... the truth is the founders love Quentin Tarantino movies,
                            especially Kill Bill where the sword of Uma Thurman is infamous. We loved the sword so much that our labs,
                            we believed must be exceptional, like the ASSKMIND steel!
                        </p>
                    </div>
                    <div className="second_box">
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={dataScienceIcon} alt="Data Science" />
                                    <h2>Data Science</h2>
                                    <p>
                                        We at ASSKMIND can show how unstructured data can be transformed into a valuable input into decision making.
                                        We present an enormous opportunity to make organizations more agile, more efficient and more competitive.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={businessAnalyticsIcon} alt="Business Analytics" />
                                    <h2>Business Analytics</h2>
                                    <p>
                                        Anticipate opportunity, take action and make an impact. Tableau helps people see and understand their data.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={sapIcon} alt="SAP" />
                                    <h2>SAP</h2>
                                    <p>
                                        Learn why IDC MarketScape has named SAP Jam a leader in the IDC MarketScape: Worldwide Enterprise Social Networks vendor assessment.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                <div className="box">
                                    <img src={mobileLabsIcon} alt="Mobile Labs" />
                                    <h2>Mobile Labs</h2>
                                    <p>
                                        Mobility has upped the stakes and made the job much more challenging for software testers.
                                        In a piece that surveys mobile tools and strategies, Christina Mulligan of SDTimes interviewed Mobile
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <div className="banner">
                <Container>
                    <h3>We've got your hiring, so you can stay focused on your business</h3>
                    <button onClick={() => window.location.href = '/contact'}>Enquire Now</button>
                </Container>
            </div>
        </main>
    );
};

export default Main;
