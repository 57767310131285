import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailIcon from '../images/icons/email.svg';
import phoneIcon from '../images/icons/phone.svg';
import markerIcon from '../images/icons/marker.svg';
import '../styles/components/Footer.css';

const Footer = () => {
    return (
        <footer>
            <Container>
                <div className="top">
                    <Row style={{ marginBottom: '25px' }}>
                        <Col xs={12} sm={12} md={6} lg={6} className="third_part">
                            <h2>ASSKMIND</h2>
                            <p>
                                ASSKMIND will work to meet your business needs, solve your technical dilemmas and provide the information you need to stay ahead of the competition.
                            </p>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} className="second_part">
                            <h2>Products</h2>
                            <ul>
                                <li><a href="https://idonate.site" target="_blank" rel="noopener noreferrer">iDonate</a></li>
                                <li><a href="#" onClick={() => alert('This page is under construction, coming soon!')}>Motelians</a></li>
                                <li><a href="#" onClick={() => alert('This page is under construction, coming soon!')}>MMO (Make My Order)</a></li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} className="first_part">
                            <h2>Contact Info</h2>
                            <ul>
                                <li><a href="mailto:info@muktsoftware.com"><img src={emailIcon} alt="Email Icon" /> hr@asskmind.com</a></li>
                                <li style={{ marginRight: '25px' }}><a href="https://wa.me/18204440552" target="_blank" rel="noopener noreferrer"><img src={phoneIcon} alt="Phone Icon" /> +1 (820) 444 0552</a></li>
                                <li><a href="https://www.google.com/maps/place/728 Lake Ridge Dr, South Elgin, IL 60177" target="_blank" rel="noopener noreferrer"><img src={markerIcon} alt="Marker Icon" /> 728 Lake Ridge Dr, South Elgin, IL 60177A</a></li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div className="bottom">
                    <p>© 2024 ASSKMIND | All Rights Reserved.</p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
