import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailIcon from '../images/icons/email.svg';
import phoneIcon from '../images/icons/phone.svg';
import markerIcon from '../images/icons/marker.svg';
import emailjs from 'emailjs-com';
import '../styles/components/Main.css';

const Contact = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_m5f188j', 'template_3g5ekfj', e.target, 'RbbXVub6F-3LZVP0a')
            .then((result) => {
                alert("Message was sent successfully");
                e.target.reset();
            }, (error) => {
                console.error("Error sending email:", error);
            });
    };

    return (
        <main>
            <Container>
                <div className="first">
                    <div className="first_box">
                        <h1><span>Contact</span></h1>
                    </div>
                    <div className="second_box contact" style={{ marginBottom: '100px' }}>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <div className="box">
                                    <form onSubmit={sendEmail}>
                                        <label htmlFor="fullname">Full name</label>
                                        <input type="text" name="fullname" id="fullname" placeholder="Enter your full name" />

                                        <label htmlFor="email">E-Mail</label>
                                        <input type="email" name="email" id="email" placeholder="Enter your e-mail" />

                                        <label htmlFor="message">Message</label>
                                        <textarea name="message" id="message" placeholder="Enter your message"></textarea>

                                        <button type="submit" style={{ marginTop: '15px', background: '#df7d4d', color: 'white', border: '0', padding: '10px', }}>Send Message</button>
                                    </form>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="box">
                                    <ul>
                                        <li>
                                            <a href="mailto:info@muktsoftware.com">
                                                <img src={emailIcon} alt="Email Icon" /> info@muktsoftware.com
                                            </a>
                                        </li>
                                        <li style={{ marginRight: '25px' }}>
                                            <a href="https://wa.me/18183891342" target="_blank" rel="noopener noreferrer">
                                                <img src={phoneIcon} alt="Phone Icon" /> +1 (818) 389-1342
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.google.com/maps/place/Chicago,+IL" target="_blank" rel="noopener noreferrer">
                                                <img src={markerIcon} alt="Marker Icon" /> Chicago, Illinois, USA
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <div className="banner">
                <Container>
                    <h3>We've got your hiring, so you can stay focused on your business</h3>
                    <button onClick={() => window.location.href = '/contact'}>Enquire Now</button>
                </Container>
            </div>
        </main>
    );
};

export default Contact;

