import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import axios from 'axios';
import '../styles/components/Main.css';

const Career = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('fixedHeader');
            if (window.pageYOffset > 0) {
                navbar.style.backgroundColor = '#21212190';
                navbar.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                navbar.style.top = '0';
            } else {
                navbar.style.backgroundColor = 'transparent';
                navbar.style.boxShadow = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get('https://admin.asskmind.com/jobs');
                setJobs(response.data);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            }
        };

        fetchJobs();
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        const form = document.getElementById('jobApplicationForm');
        if (!form) {
            console.error("Form element not found");
            return;
        }

        const formData = new FormData(form);
        const resumeFile = formData.get('resume');

        const emailData = {
            jobTitle: formData.get('jobTitle'),
            name: formData.get('name'),
            email: formData.get('email'),
            phone: formData.get('phone'),
            resume: resumeFile
        };

        emailjs.send('service_m5f188j', 'template_cuyrpcu', emailData, 'RbbXVub6F-3LZVP0a')
            .then((result) => {
                console.log("Email sent successfully");
                setShowModal(false);
                setShowSuccessModal(true);
                form.reset();
            }, (error) => {
                console.error("Error sending email:", error);
            });
    };

    const filteredJobs = jobs.filter(job => job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <main>
            <Container>
                <div className="first">
                    <div className="first_box">
                        <h1><span>Career</span></h1>
                        <b className="open_positions">Open positions</b>
                    </div>
                    <div className="second_box">
                        <Row style={{ marginBottom: '20px', justifyContent: 'center' }}>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Form.Control
                                    type="text"
                                    placeholder="Search for a job"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            {filteredJobs.map((job, index) => (
                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                    <div className="box position">
                                        <h2>{job.jobTitle}</h2>
                                        <div className="date_location">
                                            <small>Date: {new Date(job.jobDate).toLocaleDateString()}</small>
                                            <small>Location: {job.jobLocation}</small>
                                        </div>
                                        <div className="job_description">
                                            <b>Job Description</b>
                                            <p>{job.jobDescription}</p>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button variant="primary" style={{ marginTop: '20px', background: '#df7d4d', border: 'none' }} onClick={() => { setSelectedJob(job); setShowModal(true); }}>
                                                    Apply Now
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="first_box">
                        <p>
                            <span style={{ fontSize: '20px' }}>
                                We excel in optimizing delivery, streamlining maintenance, and driving down costs to maximize your IT budget efficiency.
                            </span>
                            <br /><br />
                            Whether you need assistance with projects or expert consulting services in these areas, we are here to support your organization. Please send us an email at 
                            <a href="mailto:hr@asskmind.com" style={{ textDecoration: 'none', cursor: 'pointer', color: '#df7d4d' }}> hr@asskmind.com</a> and our technical representative will get in touch with you.
                        </p>
                    </div>
                </div>
            </Container>
            <div className="banner">
                <Container>
                    <h3>We've got your hiring, so you can stay focused on your business</h3>
                    <Button onClick={() => window.location.href = '/contact'}>Enquire Now</Button>
                </Container>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} onShow={() => document.getElementById('name').focus()}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply for {selectedJob?.jobTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="jobApplicationForm" onSubmit={sendEmail} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <input type="hidden" name="jobTitle" value={selectedJob?.jobTitle} />
                        <label htmlFor="name" style={{ marginTop: '10px' }}>Full name</label>
                        <input type="text" name="name" id="name" required style={{ borderRadius: '7px', border: '1px solid #ccc', background: '#f1f1f1', padding: '5px' }} />

                        <label htmlFor="email" style={{ marginTop: '10px' }}>E-Mail</label>
                        <input type="email" name="email" id="email" required style={{ borderRadius: '7px', border: '1px solid #ccc', background: '#f1f1f1', padding: '5px' }} />

                        <label htmlFor="phone" style={{ marginTop: '10px' }}>Phone</label>
                        <input type="tell" name="phone" id="phone" required style={{ borderRadius: '7px', border: '1px solid #ccc', background: '#f1f1f1', padding: '5px' }} />

                        <label htmlFor="resume" style={{ marginTop: '10px' }}>Resume</label>
                        <input type="file" name="resume" id="resume" accept=".pdf,.doc,.docx" required />

                        <button type="submit" style={{ marginTop: '20px', background: '#df7d4d', border: 'none', color: 'white', padding: '6px' }}>Send</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Application Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Thank you for your application! We will get back to you soon.</p>
                    <Button variant="primary" onClick={() => setShowSuccessModal(false)} style={{ marginTop: '20px', background: '#df7d4d', border: 'none' }}>
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        </main>
    );
};

export default Career;
