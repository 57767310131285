// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/homepage';
import AboutPage from './pages/about';
import ClientsPage from './pages/clients';
import ContactPage from './pages/contact';
import CareerPage from './pages/career';

const App = () => {
    return (
        <Router>
            <div>
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/clients" element={<ClientsPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/career" element={<CareerPage />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
