import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/components/Main.css';

// Import client images
import unitedAirlinesLogo from '../images/icons/clients/united-airlines-logo.png';
import discoverLogo from '../images/icons/clients/discover-logo.png';
import attLogo from '../images/icons/clients/att-logo.png';
import nakumattLogo from '../images/icons/clients/nakumatt-logo.png';
import packygeLogo from '../images/icons/clients/packyge-logo.png';
import marsIncorporatedLogo from '../images/icons/clients/mars-incorporated-logo.png';
import adeyaLogo from '../images/icons/clients/adeya-logo.png';
import unitedTechLogo from '../images/icons/clients/united-tech-logo.png';
import albertsonsLogo from '../images/icons/clients/albertsons-logo.png';
import snapFinanceLogo from '../images/icons/clients/snap-finance-logo.png';
import petSmartLogo from '../images/icons/clients/pet-smart-logo.png';
import lowesLogo from '../images/icons/clients/lowes-logo.png';
import blueCrossLogo from '../images/icons/clients/blue-cross-logo.png';
import maninagarAhmedabadLogo from '../images/icons/clients/maninagar-ahmedabad-logo.png';
import fleetFarmLogo from '../images/icons/clients/fleet-farm-logo.png';
import lufthansaLogo from '../images/icons/clients/lufthansa-logo.png';
import countyOfSanDiegoLogo from '../images/icons/clients/county-of-san-diego-logo.png';
import halliburtonLogo from '../images/icons/clients/halliburton-logo.png';
import protectiveLogo from '../images/icons/clients/protective-logo.png';

const Clients = () => {
    const clientImages = [
        unitedAirlinesLogo,
        discoverLogo,
        attLogo,
        nakumattLogo,
        packygeLogo,
        marsIncorporatedLogo,
        adeyaLogo,
        unitedTechLogo,
        albertsonsLogo,
        snapFinanceLogo,
        petSmartLogo,
        lowesLogo,
        blueCrossLogo,
        maninagarAhmedabadLogo,
        fleetFarmLogo,
        lufthansaLogo,
        countyOfSanDiegoLogo,
        halliburtonLogo,
        protectiveLogo
    ];

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('fixedHeader');
            if (window.pageYOffset > 0) {
                navbar.style.backgroundColor = '#21212190';
                navbar.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                navbar.style.top = '0';
            } else {
                navbar.style.backgroundColor = 'transparent';
                navbar.style.boxShadow = 'none';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <main>
            <Container className="clients">
                <div className="first">
                    <div className="first_box">
                        <h1><span>ASSKMIND</span> Clients</h1>
                    </div>
                    <Row>
                        {/* Map through clientImages array to render client boxes */}
                        {clientImages.map((image, index) => (
                            <Col key={index} xs={6} sm={6} md={3} lg={3} className="client_box">
                                <img src={image} alt={`Client Logo ${index}`} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
            <div className="banner">
                <Container>
                    <h3>We've got your hiring, so you can stay focused on your business</h3>
                    <button onClick={() => window.location.href = '/contact'}>Enquire Now</button>
                </Container>
            </div>
        </main>
    );
};

export default Clients;
